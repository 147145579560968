import axios from 'axios';

const SendContactForm = async (contactFormData) => {
  const payload = {
    contactFormData,
  };

  return axios.post('/api/contact', payload);
};

export default SendContactForm;
