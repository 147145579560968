/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionButton from '../../../components/Button/ActionButton';

const Form = styled.form`
    ${tw`flex flex-col`}
`;

const FormResult = styled.div`
  ${tw`flex justify-center`}
`;

const FormMessage = styled.p`
${tw`font-lato tracking-wider text-sm md:text-base text-center mb-4`}
`;

const ErrorMessage = styled(FormMessage)`
  ${tw`text-red-600`}
`;

const SuccessMessage = styled(FormMessage)`
  ${tw`text-green-600`}
`;

const InputText = styled.input.attrs({ type: 'text' })`
    ${tw`bg-gray-200 mb-8 flex-shrink-0 font-montserrat text-sm font-medium p-4 outline-none focus:border`}
    ${(props) => props.error && tw`border border-red-500 focus:border-red-500`}
`;

const CheckBoxContainer = styled.div`
    ${tw`flex items-center w-1/2`}
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    ${tw`mr-4 w-4 h-4 flex-shrink-0`}
    &:last-child {
        margin-right: 0;
    }
`;

const Label = styled.label`
${tw`font-lato font-semibold text-sm tracking-wide`}
`;

const TextArea = styled.textarea`
    ${tw`bg-gray-200 h-64 font-montserrat text-sm font-medium p-8 mb-8 outline-none`}
    ${(props) => props.error && tw`border border-red-500 focus:border-red-500`}
`;

const ProjectChoice = styled.div`
    ${tw`flex mb-8 py-4`}
`;

const schema = yup.object().shape({
  lastname: yup.string().trim().required(),
  tel: yup.string().trim().matches('^((\\+)33|0)[1-9](\\d{2}){4}$').required(),
  email: yup.string().email().required(),
  city: yup.string().trim().required(),
  message: yup.string().trim().required(),
});

const ContactForm = ({
  sendContactForm, isSubmitting, isError, isSuccess,
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={handleSubmit(sendContactForm)}>
      <InputText name="lastname" placeholder="Nom :" ref={register} error={errors.hasOwnProperty('lastname')} />
      <InputText type="email" name="email" placeholder="Mail :" ref={register} error={errors.hasOwnProperty('email')} />
      <InputText type="tel" name="tel" placeholder="Téléphone: " ref={register} error={errors.hasOwnProperty('tel')} />
      <ProjectChoice>
        <CheckBoxContainer>
          <CheckBox type="checkbox" name="architecture" ref={register} />
          <Label htmlFor="architecture">Projet architecture d'intérieur</Label>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <CheckBox type="checkbox" name="decoration" ref={register} />
          <Label htmlFor="decoration">Projet décoration</Label>
        </CheckBoxContainer>
      </ProjectChoice>
      <InputText name="city" placeholder="Ville où se trouve le bien de votre projet" ref={register} error={errors.hasOwnProperty('city')} />
      <TextArea name="message" placeholder="Votre projet en quelques mots" ref={register} error={errors.hasOwnProperty('message')} />
      <FormResult>
        {isError && (
          <ErrorMessage>
            Une erreur s'est produite, merci de reesayer plus tard.
          </ErrorMessage>
        )}
        {isSuccess && (
          <SuccessMessage>
            Votre demande a bien été prise en compte 🙂
          </SuccessMessage>
        )}
      </FormResult>
      <ActionButton type="submit" loading={isSubmitting} disabled={isSubmitting}>Envoyer</ActionButton>
    </Form>
  );
};

ContactForm.defaultProps = {
  isError: false,
  isSubmitting: false,
  isSuccess: false,
  sendContactForm: () => {},
};

ContactForm.propTypes = {
  isError: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isSuccess: PropTypes.bool,
  sendContactForm: PropTypes.func,
};

export default ContactForm;
