/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import tw from 'twin.macro';
import Hero from '../../components/Hero/Hero';
import Image from '../../components/Image/Image';
import BaseSection from '../../components/Section/Section';
import SendContactForm from '../../services/sendContactForm.service';
import ContactForm from './ContactForm/ContactForm';
import Page from '../../components/Page/Page';
import contactData from './data';

const Content = styled.main`
  ${tw`max-w-screen-xl py-20 px-4 mx-auto flex flex-col items-center`}
  width: 85%;
`;

const Title = styled.h2`
  ${tw`text-center font-extrabold font-montserrat text-lg`}
`;

const SubTitle = styled.p`
  ${tw`text-center font-medium font-montserrat text-sm`}
`;

const Section = styled(BaseSection)`
  ${tw`grid grid-cols-1 md:grid-cols-2 gap-16 mt-20`}
`;

const ImageSection = styled.div`
  ${tw``}
`;

const ContactContainer = styled.div`
  ${tw``}
`;

const ContactParagraph = styled.p`
  ${tw`font-lato leading-6 text-sm`}
  font-size: 15px;
`;

const Contact = ({ pageContext: { seo, title } }) => {
  const [sendContactForm, { isLoading, isError, isSuccess }] = useMutation(
    SendContactForm,
  );

  const onSendContactForm = useCallback(
    (data) => {
      sendContactForm(data);
    },
    [sendContactForm],
  );

  return (
    <Page
      seo={seo}
      header={() => <Hero title={title} />}
      main={() => (
        <Content>
          <Title>Vous avez un projet ?</Title>
          <SubTitle>On en parle ?</SubTitle>
          <Section>
            <ImageSection>
              <Image src={contactData.image.src} alt={contactData.image.alt} />
            </ImageSection>
            <ContactContainer>
              <ContactParagraph className="font-bold">Anne-Sophie Lagoët - Architecte d’intérieur</ContactParagraph>
              <ContactParagraph>
                Cabinet d’Architecture d’Intérieur et de Décoration à
                Montmorency
              </ContactParagraph>
              <ContactParagraph className="font-bold">Tel : 06.66.90.63.21</ContactParagraph>
              <ContactParagraph className="font-bold mb-8">(Je ne prends pas de stagiaire)</ContactParagraph>
              <ContactForm
                sendContactForm={onSendContactForm}
                isSubmitting={isLoading}
                isError={isError}
                isSuccess={isSuccess}
              />
            </ContactContainer>
          </Section>
        </Content>
      )}
    />
  );
};

Contact.propTypes = {
  seo: PropTypes.any.isRequired,
};

export default Contact;
